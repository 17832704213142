import { imageQueryKeys, useGetLibImages, useGetPublicImages } from '@/hooks/image'
import { reactive, ref, computed } from '@vue/composition-api'
import { Component, Vue } from 'vue-property-decorator'
import get from 'lodash.get'
import { QueryClient, useQueryClient } from 'vue-query'
import { gifQueryKey, useGetPublicGifs, useGetTenorGif } from '@/hooks/gif'
import { useGetLibVideos, useGetPublicVideos } from '@/hooks/videos'
import { useGetStabilityResults } from '@/hooks/openAi'
import AdoriService from '@/services/adori'
import { SetupContext } from '@vue/composition-api'

@Component({
  setup(props, context: SetupContext) {
    const queryClient = useQueryClient()
    const orientation = computed(() => (get(props, 'galleryParent', false) === 'PODCAST_SHOW' ? 'squarish' : 'NONE'))

    const currentContentTab = ref('STOCK') //'LIB', 'GENERATE'
    const media = ref('image')
    const libMedia = ref('image')
    const selectedImageTab = ref('UNSPLASH')
    const searchText = ref('')
    // Stock Image Library
    const imageSearchParams = reactive({
      query: searchText,
      limit: 8,
      offset: 0,
      searchLibrary: selectedImageTab,
      orientation,
    })
    const isImageSearchActive = computed(
      () => currentContentTab.value === 'STOCK' && media.value === 'image' && !!searchText.value
    )
    const { data: stockImageData, isFetching: isStockImageLoading } = useGetPublicImages(imageSearchParams, {
      enabled: isImageSearchActive,
    })
    // My library
    const isMyLibActive = computed(() => currentContentTab.value === 'LIB')
    const libImageParams = reactive({
      limit: 8,
      offset: 0,
    })
    const { data: libImageData, isFetching: isLibImageLoading } = useGetLibImages(libImageParams, {
      enabled: isMyLibActive,
    })

    // Gif Stock
    const selectedGifTab = ref('GIPHY')
    const gifSearchText = ref('')
    const gifSearchParams = reactive({
      q: gifSearchText,
      limit: 8,
      offset: 0,
      searchLibrary: selectedGifTab,
    })
    const isGifSearchActive = computed(
      () => media.value === 'gif' && ['GIPHY', 'TRENDING'].includes(selectedGifTab.value)
    )
    const { data: publicGifData, isFetching: isPublicGifLoading } = useGetPublicGifs(gifSearchParams, {
      enabled: isGifSearchActive,
    })
    const tenorSearchParams = reactive({
      q: gifSearchText,
    })

    const isTenorSearchActive = computed(
      () => media.value === 'gif' && ['TENOR'].includes(selectedGifTab.value) && !!gifSearchText.value
    )
    const {
      data: tenorData,
      isLoading: isTenorFetching,
      hasNextPage: hasNextTenorGifs,
      fetchNextPage: nextTenorGifs,
      isFetchingNextPage: isFetchingNextTenorGifs,
    } = useGetTenorGif(tenorSearchParams, {
      enabled: isTenorSearchActive,
    })

    // Stock Video
    const selectedVideoTab = ref('PEXELS')
    const videoSearchText = ref('')
    const videoSearchParams = reactive({
      query: videoSearchText,
      limit: 8,
      offset: 0,
      searchLibrary: selectedVideoTab,
    })
    const isVideoSearchActive = computed(
      () => currentContentTab.value === 'STOCK' && media.value === 'video' && !!videoSearchText.value
    )
    const { data: publicVideoData, isFetching: isPublicVideoLoading } = useGetPublicVideos(videoSearchParams, {
      enabled: isVideoSearchActive,
    })

    const libVideoParams = reactive({
      limit: 8,
      offset: 0,
    })
    const { data: libVideoData, isFetching: isLibVideoLoading } = useGetLibVideos(libVideoParams, {
      enabled: isMyLibActive,
    })

    // AI

    const stabilityParams = reactive({
      prompt: '',
      aspect_ratio: '1:1',
      model: 'essential-v2',
      width: '512',
      height: '512',
    })
    const interval: any = ref(false)
    const isStabilityActive = computed(() => !!stabilityParams.prompt && currentContentTab.value === 'GENERATE')

    const { data: stabilityResults, isFetching: isStabilityLoading } = useGetStabilityResults(
      stabilityParams,
      {
        enabled: isStabilityActive,
      },
      interval,
      async (data: any) => {
        if (data.url) {
          await AdoriService.uploadImage(context.root.$store.getters.networkId, {
            url: data.url,
          })
          queryClient.invalidateQueries(imageQueryKeys.LIB_IMAGES)
        }
      }
    )

    return {
      queryClient,
      selectedImageTab,
      currentContentTab,
      media,
      libMedia,
      searchText,
      imageSearchParams,
      stockImageData,
      isStockImageLoading,
      libImageParams,
      libImageData,
      isLibImageLoading,
      selectedGifTab,
      gifSearchText,
      gifSearchParams,
      publicGifData,
      isPublicGifLoading,
      tenorData,
      isTenorFetching,
      hasNextTenorGifs,
      nextTenorGifs,
      isFetchingNextTenorGifs,
      selectedVideoTab,
      videoSearchParams,
      publicVideoData,
      isPublicVideoLoading,
      libVideoParams,
      libVideoData,
      isLibVideoLoading,
      videoSearchText,
      stabilityParams,
      stabilityResults,
      isStabilityLoading,
    }
  },
})
export default class ContentMixin extends Vue {
  libMedia!: string
  media!: string
  selectedImageTab!: string
  currentContentTab!: string
  searchText!: string
  imageSearchParams!: any
  stockImageData!: any
  isStockImageLoading!: boolean
  libImageParams!: any
  libImageData!: any
  isLibImageLoading!: boolean

  queryClient!: QueryClient

  get selectedRangeStart() {
    return this.currentContentTab === 'LIB' ? this.libImageParams.offset + 1 : this.imageSearchParams.offset + 1
  }

  get selectedRangeEnd() {
    return this.currentContentTab === 'LIB'
      ? this.libImageParams.offset + this.imageList.length
      : this.imageSearchParams.offset + this.imageList.length
  }

  get imageList() {
    return this.currentContentTab === 'LIB'
      ? get(this.libImageData, 'data', []).map((img: any) => ({
          url: img.url,
          previewUrl: img.thumbnailURL,
          source: 'adori',
          id: img.id,
        }))
      : //   : this.selectedImageTab === 'DALLE'
        //   ? get(this, 'dalleResults', []).map((img: any) => ({
        //       url: img.url,
        //       previewUrl: img.url,
        //       source: 'dalle',
        //       id: crypto.randomUUID(),
        //     }))
        //   : this.selectedImageTab === 'AI'
        //   ? get(this.stabilityResults, 'output', []).map((img: any) => ({
        //       url: img,
        //       previewUrl: img,
        //       source: 'stability',
        //       id: this.stabilityResults?.id || crypto.randomUUID(),
        //     }))
        get(this.stockImageData, 'data', []).map((img: any) => ({
          url: img.urls.full,
          previewUrl: img.urls.thumb,
          source: 'unsplash',
          id: img.id,
          user: img.user || null,
          downloadUrl: img.urls.download,
        }))
  }

  get totalImageCount() {
    return this.currentContentTab === 'LIB' ? get(this.libImageData, 'count', 0) : get(this.stockImageData, 'count', 0)
  }

  handleNavigation(nav: string) {
    if (this.currentContentTab === 'LIB') {
      nav === 'next' && this.libImageParams.offset <= this.totalImageCount && (this.libImageParams.offset += 8)
      nav === 'prev' && this.libImageParams.offset >= 8 && (this.libImageParams.offset -= 8)
    } else {
      nav === 'next' && this.imageSearchParams.offset <= this.totalImageCount && (this.imageSearchParams.offset += 8)
      nav === 'prev' && this.imageSearchParams.offset >= 8 && (this.imageSearchParams.offset -= 8)
    }
  }

  resetOffset() {
    this.imageSearchParams.offset = 0
  }

  resetImageGallery() {
    this.queryClient.invalidateQueries(imageQueryKeys.LIB_IMAGES)
  }

  //   gif lib
  selectedGifTab!: string
  gifSearchText!: string
  gifSearchParams!: any
  publicGifData!: any
  isPublicGifLoading!: boolean
  tenorData!: any
  isTenorFetching!: boolean
  hasNextTenorGifs!: boolean
  nextTenorGifs!: any
  isFetchingNextTenorGifs!: boolean

  get selectedGifRangeStart() {
    return this.gifSearchParams.offset + 1
  }

  get selectedGifRangeEnd() {
    return this.gifSearchParams.offset + this.gifList.length
  }

  get gifList() {
    return ['GIPHY', 'TRENDING'].includes(this.selectedGifTab)
      ? get(this.publicGifData, 'data.data', []).map((img: any) => ({
          url: img.images.original.url,
          previewUrl: img.images.preview_gif.url,
          source: 'GIPHY',
          id: img.id,
          user: img.username || null,
        }))
      : this.tenorData?.length
      ? this.tenorData.map((obj: any) => ({
          url: obj.media_formats.mediumgif.url,
          previewUrl: obj.media_formats.tinygif.url,
          source: 'TENOR',
          id: obj.id,
          user: null,
        }))
      : []
  }

  get totalGifCount() {
    return get(this.publicGifData, 'data.pagination.total_count', 0)
  }

  handleGifNavigation(nav: string) {
    nav === 'next' && this.gifSearchParams.offset <= this.totalGifCount && (this.gifSearchParams.offset += 8)
    nav === 'prev' && this.gifSearchParams.offset >= 8 && (this.gifSearchParams.offset -= 8)
  }

  resetGifOffset() {
    this.gifSearchParams.offset = 0
  }

  resetGifGallery() {
    this.queryClient.invalidateQueries(gifQueryKey.PUBLIC_GIPHY)
  }

  //   video lib
  selectedVideoTab!: string
  publicVideoData!: any
  isPublicVideoLoading!: boolean
  libVideoParams!: any
  libVideoData!: any
  isLibVideoLoading!: boolean
  videoSearchParams!: any
  videoSearchText!: string

  get selectedVideoRangeStart() {
    return this.currentContentTab === 'LIB' ? this.libVideoParams.offset + 1 : this.videoSearchParams.offset + 1
  }

  get selectedVideoRangeEnd() {
    return this.currentContentTab === 'LIB'
      ? this.libVideoParams.offset + this.videoList.length
      : this.videoSearchParams.offset + this.videoList.length
  }

  get videoList() {
    return this.currentContentTab === 'LIB'
      ? get(this.libVideoData, 'data', []).map((video: any) => ({
          originalUrl: video.originalUrl,
          previewUrl: get(video, 'mp4Urls.thumb.url', ''),
          source: 'adori',
          id: video.id,
          thumbNail: video.thumbnailUrl,
        }))
      : get(this.publicVideoData, 'data', []).map((video: any) => ({
          originalUrl: get(video, 'urls.large.url', ''),
          previewUrl: get(video, 'urls.tiny.url', ''),
          thumbNail: get(video, 'urls.tiny.thumbnail', ''),
          source: 'adori',
          id: video.id,
        }))
  }

  get totalVideoCount() {
    return this.currentContentTab === 'LIB' ? get(this.libVideoData, 'count', 0) : get(this.publicVideoData, 'count', 0)
  }

  handleVideoNavigation(nav: string) {
    if (this.currentContentTab === 'LIB') {
      nav === 'next' && this.libVideoParams.offset <= this.totalVideoCount && (this.libVideoParams.offset += 8)
      nav === 'prev' && this.libVideoParams.offset >= 8 && (this.libVideoParams.offset -= 8)
    } else {
      nav === 'next' && this.videoSearchParams.offset <= this.totalVideoCount && (this.videoSearchParams.offset += 8)
      nav === 'prev' && this.videoSearchParams.offset >= 8 && (this.videoSearchParams.offset -= 8)
    }
  }

  //   AI
  stabilityParams!: any
  stabilityResults!: any
  isStabilityLoading!: boolean

  get aiImg() {
    return get(this, 'stabilityResults.url', null)
  }
}
